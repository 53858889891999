<template>
    <div class="usehelp xi">
        <hd-com></hd-com>
        <div class="banner">
            <div class="banner-text">
                <h3>使用帮助</h3>
                <p>当前位置：<router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>使用帮助</a></p>
            </div>
        </div>
        <div class="content-wrap">
            <div class="content">
                <!--<el-scrollbar class="left-nav-wrap" style="height: 690px;">
                    &lt;!&ndash; 具体要包裹的内容 &ndash;<i class="el-icon-arrow-right"></i>
                    <div class="left-nav">
                        <h3>使用帮助</h3>
                        <ul class="nav-box">
                            <li v-for="(sideNav,index) in diagnoseList"
                                :key="index"
                                @click="jump(index)"
                                :class="index==0?'active':''"><span></span>{{sideNav.categoryName}}</li>
                            &lt;!&ndash;class="active"&ndash;<i class="el-icon-arrow-right"></i>
                        </ul>
                    </div>
                </el-scrollbar>
                <div class="ny_container">
                    <div class="usehelp-box">
                        <div class="use-tit">
                            <h3>Using <span>help</span></h3>
                            <p>使用帮助</p>
                        </div>
                        <el-collapse class="usehelp-list" accordion>
                            <el-collapse-item>
                                <template slot="title">
                                    一致性 Consistency<i class="header-icon el-icon-info"></i>
                                </template>
                                <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
                                <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
                            </el-collapse-item>
                            <el-collapse-item title="反馈 Feedback">
                                <div>控制反馈：通过界面样式和交互动效让用户可以清晰的感知自己的操作；</div>
                                <div>页面反馈：操作后，通过页面元素的变化清晰地展现当前状态。</div>
                            </el-collapse-item>
                            <el-collapse-item title="效率 Efficiency">
                                <div>简化流程：设计简洁直观的操作流程；</div>
                                <div>清晰明确：语言表达清晰且表意明确，让用户快速理解进而作出决策；</div>
                                <div>帮助用户识别：界面简单直白，让用户快速识别而非回忆，减少用户记忆负担。</div>
                            </el-collapse-item>
                            <el-collapse-item title="可控 Controllability">
                                <div>用户决策：根据场景可给予用户操作建议或安全提示，但不能代替用户进行决策；</div>
                                <div>结果可控：用户可以自由的进行操作，包括撤销、回退和终止当前操作等。</div>
                            </el-collapse-item>
                        </el-collapse>
                        <div class="tips">
                            如果这里没有您想找的问题，您还可以联系Email：nairo@woshipm.com
                        </div>
                    </div>
                </div>-->
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
    import hdCom from '../../components/header'
    import ftCom from '../../components/footer'
    // import rtBar from "../../components/right";
    export default {
        name: "usehelp",
        components: {
            hdCom,
            ftCom,
            // rtBar
        },
    }
</script>

<style scoped>
    .banner {
        height: 116px;
        background: url("../../assets/img/jbk_banner.png")center no-repeat;
    }

    .banner-text {
        width: 1200px;
        margin: 0 auto;
    }

    .banner-text h3 {
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    .banner-text p {
        font-size: 18px;
        color: #ffffff;
    }

    .banner-text p a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }

    .content-wrap {
        background: #fcfcfc;
    }

    .content {
        position: relative;
        width: 1200px;
        background: none;
        padding: 30px 0;
        margin: 0 auto;
    }

    .left-nav-wrap {
        position: absolute;
        width: 260px;
        max-height: 520px;
        box-shadow: 5px 0 8px rgba(224, 224, 224, 0.3);
    }

    .left-nav {
        width: 100%;
        background: #FFFFFF;
        padding: 20px 0;
        box-sizing: border-box;
    }

    .left-nav > h3 {
        padding-left: 20px;
        font-size: 18px;
        font-weight: normal;
    }

    .left-nav > ul > li {
        position: relative;
        padding: 13px 30px 13px;
        font-size: 16px;
        color: #666666;
        cursor: pointer;
    }

    .left-nav > ul > li span {
        position: absolute;
        left: 0;
        top: calc(50% - 4px);
        display: inline-block;
        width: 20px;
        height: 8px;
        margin-right: 20px;
    }

    .left-nav > ul > li.active,.left-nav > ul > li:hover {
        color: #1fb7a6;
    }

    .left-nav > ul > li.active span {
        background: #03b29e;
    }
    .ny_container{
        margin-left: 280px;
        min-height: 690px;
    }
    .ny_container .usehelp-box{
        padding: 20px;
        margin-bottom: 15px;
        background: #fff;
        box-shadow: 3px 0px 5px rgba(224, 224, 224, 0.3);
    }
    .ny_container .usehelp-box .use-tit{
        border-bottom: 1px solid #e5e5e5;
    }
    .ny_container .usehelp-box .use-tit h3{
        font-size: 26px;
    }
    .ny_container .usehelp-box .use-tit h3 span{
        color: #1fb7a6;
    }
    .ny_container .usehelp-box .use-tit p{
        color: #666666;
        margin-bottom: 15px;
    }
    .ny_container .usehelp-box .usehelp-list{
        margin: 20px 0;
    }
</style>
<style>
    .usehelp .el-collapse-item{
        margin-bottom: 15px;
    }
    .usehelp .el-collapse-item__header {
        background-color: #1fb7a6;
        color: #ffffff;
        font-size: 16px;
        padding: 0 20px;
    }

    .usehelp .el-collapse-item__header:hover {
        color: #ffffff;
    }

    .usehelp .el-collapse-item__wrap {
        border-left: 1px solid #EBEEF5;
        border-right: 1px solid #EBEEF5;
    }

    .usehelp .el-collapse-item__content {
        padding: 15px;
    }
</style>
